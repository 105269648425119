/* eslint-disable jsx-a11y/anchor-is-valid */
import styled from "styled-components";
import { useState } from "react";
import { useLocation, Link } from "react-router-dom";
const Navbar = styled.nav`
  background: ${(props) => (props.menuIsOpen ? "#fff !important" : "none")};

  .navbar-item {
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .navbar-item img {
    margin: 10px 0 0 10px;
    width: 120px;
    max-height: 120px !important;
  }

  .navbar-brand .navbar-item {
    margin-bottom: 5px;
  }

  .navbar-burger:hover {
    background: none;
  }

  .navbar-menu a.navbar-item:hover {
    border-bottom: 1px solid
      ${(props) => (props.headerVisible ? "#fff" : "#000")};
    background: none;
    color: ${(props) => (props.headerVisible ? "#fff" : "#000")};
  }
  .navbar-link.is-active,
  .navbar-link:focus,
  .navbar-link:focus-within,
  .navbar-link:hover,
  a.navbar-item.is-active,
  a.navbar-item:focus,
  a.navbar-item:focus-within,
  a.navbar-item:hover {
    background: none;
    outline: none;
    color: ${(props) =>
    props.menuIsOpen ? "#000" : props.headerVisible ? "#fff" : "#000"};
  }
  .navbar-item,
  .navbar-link {
    color: ${(props) =>
    props.menuIsOpen ? "#000" : props.headerVisible ? "#fff" : "#000"};
  }
  .navbar-burger {
    color: ${(props) =>
    props.menuIsOpen ? "#4a4a4a" : props.headerVisible ? "#fff" : "#4a4a4a"};
  }
  @media (max-width: 768px) {
    background: ${(props) =>
    props.isHome ? (props.headerVisible ? "none" : "#fff") : "#fff"};
  }
  @media screen and (min-width: 1024px) {
    .navbar-item,
    .navbar-link {
      align-items: flex-start;
    }
  }

  @media screen and (max-width: 767px) {
    .navbar-item img {
      width: 60px;
      margin: 0;
      max-height: 60px !important;
    }
    .navbar-menu a.navbar-item:hover,
    .navbar-menu a.navbar-item:focus {
      color: #000;
    }
  }
`;
function Header({ headerVisible }) {
  const location = useLocation();
  const isHome = location.pathname === "/" ? true : false;
  const shouldHeaderBeVisible = isHome ? headerVisible : false;
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const isActiveClassName = menuIsOpen ? "is-active" : "";
  return (
    <Navbar
      className="navbar is-fixed-top"
      role="navigation"
      aria-label="main navigation"
      headerVisible={shouldHeaderBeVisible}
      isHome={isHome}
      menuIsOpen={menuIsOpen}
    >
      <div className="navbar-brand">
        <Link className="navbar-item" to="/">
          <img
            src={
              menuIsOpen && shouldHeaderBeVisible
                ? "/logo.svg"
                : shouldHeaderBeVisible
                  ? "/logo-white.svg"
                  : "/logo.svg"
            }
            alt="Wproduction"
          />
        </Link>

        <a
          role="button"
          className={`navbar-burger ${isActiveClassName}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbar"
          onClick={() => setMenuIsOpen(!menuIsOpen)}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbar" className={`navbar-menu ${isActiveClassName}`}>
        <div className="navbar-end">
          <div className="navbar-item">
            <Link className="navbar-item" to="/about">
              About
            </Link>
            <a
              className="navbar-item"
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/wproduction.de"
            >
              Instagram
            </a>
            <a
              className="navbar-item"
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/wproduction.de"
            >
              Facebook
            </a>
          </div>
        </div>
      </div>
    </Navbar>
  );
}

export default Header;
