import React from "react";
import ReactPlayer from "react-player";

const VideoPlayer = (props) => {
  const { sources, config } = props;

  return (
    <ReactPlayer
      url={sources}
      playing
      loop
      muted
      playsinline
      config={{
        file: {
          attributes: {
            style: {
              width: "100%",
              height: "100%",
              objectFit: "cover",
            },
          },
        },
        vimeo: {
          playerOptions: {
            transparent: false,
          },
        },
      }}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        overflow: "hidden",
        zIndex: 0,
        objectFit: "cover",
      }}
      height="100%"
      width="100%"
      {...config}
    />
  );
};

export default VideoPlayer;
