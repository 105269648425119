/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";

const ResponsiveImage = (props) => {
  const { src, customSizes, noLazy } = props;

  if (!src) return null;

  const fileEnding = src.split(".").pop();
  const srcWithoutExtension = src.replace(`.${fileEnding}`, "");
  const sizesWithFallback = customSizes || [1200, 800, 380, 200, 1];
  if (process.env.NODE_ENV === "production" && !src.endsWith(".svg"))
    return (
      <picture>
        {/* Very big */}
        {sizesWithFallback.map((size, index) => {
          const suffix = index === 0 ? "" : `_${5 - index}x`;
          return (
            <>
              <source
                media={`(min-width: ${size}px)`}
                srcSet={`${srcWithoutExtension}${suffix}.webp`}
                type="image/webp"
              />
              <source
                media={`(min-width: ${size}px)`}
                srcSet={`${srcWithoutExtension}${suffix}.${fileEnding}`}
              />
            </>
          );
        })}

        <img {...props} src={src} loading={noLazy ? undefined : "lazy"} />
      </picture>
    );
  return <img {...props} src={src} loading={noLazy ? undefined : "lazy"} />;
};

export default ResponsiveImage;
