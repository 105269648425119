import React from "react";
import SEOHeader from "../components/SEOHeader";
import Content from "../i18n/de";
import SectionHelper from "../components/SectionHelper";
import VideoCarousel from "../components/VideoCarousel";

function Projects() {
  return (
    <>
      <SEOHeader {...Content.meta.projects} />
      <SectionHelper hideContainer={true}>
        <VideoCarousel />
      </SectionHelper>
    </>
  );
}

export default Projects;
