import styled from "styled-components";
import { Link } from 'react-router-dom'

const FooterContainer = styled.div`
  padding: 60px 0 0;
  text-align: center;
  a {
    font-size: 20px;
    font-weight: 500;
    border-left: 1px solid #000;
    padding: 0 8px;
    color: #000;
    &:first-child {
      border: none;
      padding-left: 0;
    }
  }
  p {
    font-size: 18px;
    color: #000;
  }
`;

function Footer() {
  return (
    <FooterContainer>
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-12-mobile">
            <Link to="/impressum">Impressum</Link>
            <Link to="/datenschutz">Datenschutz</Link>
            <br />
            <p>© WPRODUCTION 2021 GbR</p>
          </div>
        </div>
      </div>
    </FooterContainer>
  );
}

export default Footer;
