/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { sendContactForm } from "../services/sendContactForm";
import ClipLoader from "react-spinners/ClipLoader";

const ContactContainer = styled.div`
  p,
  a {
    color: #000;
  }
  @media (max-width: 992px) {
    text-align: center;
  }
  @media (max-width: 1024px) {
    padding: 50px 20px;
    .field {
      text-align: left;
    }
  }
`;

const Title = styled.h2`
  font-size: 60px;
`;

const Label = styled.label`
  color: black;
`;

const Input = styled.input`
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
  width: 100%;
  box-shadow: none;
  :focus {
    outline: none !important;
    box-shadow: none !important;
  }
`;

const StyledTextarea = styled.textarea`
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
  width: 100%;
  :focus {
    outline: none !important;
    box-shadow: none !important;
  }
`;

const RadioInput = styled.input`
  margin-right: 5px;
`;

const StyledP = styled.p`
  margin-top: 330px;
  @media (max-width: 768px) {
    margin-top: 80px;
  }
`;

const SendButton = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        transform: scale(1.05);
        transform-origin: left;
      }
    `}
`;

const SendButtonTitle = styled.h5<{ disabled: boolean }>`
  color: ${({ disabled }) => (disabled ? "grey" : "black")};
`;

const ArrowIcon = styled.img`
  height: 40px;
  width: 40px;
`;

const Spinner = styled(ClipLoader)``;

type ContactError = "name" | "message" | "email" | "sending";

function Contact() {
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [message, setMessage] = useState("");
  const [acceptedRadio, setAcceptedRadio] = useState(false);
  const [errors, setErrors] = useState<ContactError[]>([]);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [successSending, setSuccessSending] = useState(false);

  const pushError = (error: ContactError) => {
    setErrors((currentErrors: ContactError[]) =>
      currentErrors.includes(error) ? currentErrors : [...currentErrors, error]
    );
  };

  useEffect(() => {
    if (errors.length > 0) setErrors([]);
  }, [email, message]);

  useEffect(() => {
    if (successSending) {
      const timer = setTimeout(() => {
        setSuccessSending(false);
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [successSending]);

  const handleSend = async () => {
    let errorCount = 0;
    if (!acceptedRadio) return;
    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(email)) {
      pushError("email");
      errorCount++;
    }
    if (message.length < 1) {
      pushError("message");
      errorCount++;
    }
    if (name.length < 1) {
      pushError("name");
      errorCount++;
    }
    // Send Email

    if (errorCount === 0) {
      try {
        console.log("SENIDNG");
        setSendingEmail(true);
        const response = await sendContactForm(
          name,
          company,
          telephone,
          email,
          message
        );
        if (response) {
          setCompany("");
          setEmail("");
          setName("");
          setTelephone("");
          setMessage("");
          setAcceptedRadio(false);
          setSuccessSending(true);
        } else pushError("sending");
        setSendingEmail(false);
      } catch (e) {
        pushError("sending");
        setSendingEmail(false);
      }
    }
  };

  return (
    <ContactContainer>
      <div className="container">
        <div className="columns is-multiline is-mobile">
          <div className="column is-12-mobile is-8-desktop is-6-widescreen">
            <div className="columns is-multiline is-mobile">
              <div className="column is-12 mb-6 mt-6 p-0">
                <Title>GET IN TOUCH.</Title>
              </div>
              <div className="column is-12-mobile is-6-tablet is-6-desktop is-5-widescreen">
                <div className="field">
                  <Label>UNTERNEHMEN</Label>
                  <div className="control">
                    <Input
                      className="input"
                      type="text"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="column is-12-mobile is-offset-1-widescreen is-6-tablet is-6-desktop is-5-widescreen">
                <div className="field">
                  <Label>E-MAIL*</Label>
                  <div className="control">
                    <Input
                      className="input"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.includes("email") && (
                      <p className="help is-danger mb-0">
                        Die Email-Adresse ist ungültig
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="column is-12-mobile is-6-tablet is-6-desktop is-5-widescreen">
                <div className="field">
                  <Label>NAME*</Label>
                  <div className="control">
                    <Input
                      className="input"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    {errors.includes("name") && (
                      <p className="help is-danger mb-0">
                        Bitte geben Sie einen Namen ein
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="column is-12-mobile is-offset-1-widescreen is-6-tablet is-6-desktop is-5-widescreen">
                <div className="field">
                  <Label>TELEFON</Label>
                  <div className="control">
                    <Input
                      className="input"
                      type="text"
                      value={telephone}
                      onChange={(e) => setTelephone(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="column is-12-mobile is-11-desktop">
                <div className="field">
                  <Label>NACHRICHT*</Label>
                  <div className="control">
                    <StyledTextarea
                      className="input"
                      rows={4}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                  {errors.includes("message") && (
                    <p className="help is-danger mb-0">
                      Bitte geben Sie eine Nachricht ein
                    </p>
                  )}
                </div>
              </div>
              <div className="column is-12 pb-0">
                <div className="field">
                  <Label className="radio">
                    <RadioInput
                      type="radio"
                      name="dataProtection"
                      checked={acceptedRadio}
                      onClick={() => setAcceptedRadio((a) => !a)}
                      onChange={() => {}}
                    />
                    ICH HABE DIE DATENSCHUTZBESTIMMUNGEN GELESEN UND AKZEPTIERE
                    DIESE
                  </Label>
                </div>
              </div>
              <div className="column is-12">
                {sendingEmail ? (
                  <Spinner color="blue" />
                ) : (
                  <SendButton onClick={handleSend} disabled={!acceptedRadio}>
                    <SendButtonTitle disabled={!acceptedRadio}>
                      SENDEN
                    </SendButtonTitle>
                    <ArrowIcon
                      src="/icons/ic_arrow_right.svg"
                      alt=""
                    ></ArrowIcon>
                  </SendButton>
                )}
                {errors.includes("sending") && (
                  <p className="help is-danger mb-0">
                    Es ist ein Fehler aufgetreten
                  </p>
                )}
                {successSending && (
                  <p className="help is-success mb-0">
                    Nachricht wurde erfolgreich gesendet
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="column is-offset-1-desktop is-3-desktop is-offset-3-widescreen is-3-widescreen">
            <StyledP>
              WProduction GbR <br />
              Dreihalde 10 <br />
              73527 Schwäbisch Gmünd <br />
              <br />
              Telefon: <a href="tel:+491703860513">0170 386 05 13</a>
              <br />
              E-Mail:{" "}
              <a href="mailto:info@wproduction.de">info@wproduction.de</a>
            </StyledP>
          </div>
        </div>
      </div>
    </ContactContainer>
  );
}

export default Contact;
