import Observer from "@researchgate/react-intersection-observer";
import SEOHeader from "../components/SEOHeader";
import Contact from "../components/Contact";
import styled from "styled-components";
import Content from "../i18n/de";
import VideoList from "../components/VideoList";
import VideoPlayer from "./../videoPlayer.js";

const videoOptions = {
  sources: [
    {
      src: "/videos/hero.mp4",
      type: "video/mp4",
    },
  ],
};

const Hero = styled.section`
  position: relative;
`;

const HeroBody = styled.div`
  background: rgba(0, 0, 0, 0.5) !important;
  z-index: 3;
  text-align: center;
  h1 {
    color: #fff;
    font-size: 100px;
    line-height: 1;
    font-weight: 400;
    margin: 0;
  }
  h2 {
    color: #fff;
    font-size: 45px;
    font-weight: 400;
  }
  img {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 50px;
    height: 35px;
    width: 35px;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(276deg)
      brightness(108%) contrast(102%);
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    transform-origin: bottom;
    animation-name: bounce-1;
    animation-timing-function: linear;
    @keyframes bounce-1 {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-20px);
      }
      100% {
        transform: translateY(0);
      }
    }
  }
  @media (max-width: 767px) {
    h1 {
      font-size: 54px;
    }
    h2 {
      font-size: 24px;
    }
  }
`;

function Home({ setHeaderVisible }) {
  const handleIntersection = (event) => {
    setHeaderVisible(event.isIntersecting);
  };
  return (
    <>
      <SEOHeader {...Content.meta.home} />
      <Observer onChange={handleIntersection}>
        <Hero className="hero is-fullheight">
          <VideoPlayer {...videoOptions} />
          <HeroBody className="hero-body">
            <div className="container">
              <h1>V create</h1>
              <h2>Videos for you.</h2>
            </div>
            <img src="/icons/ic_down.svg" alt=""></img>
          </HeroBody>
        </Hero>
      </Observer>
      <VideoList />
      <Contact />
    </>
  );
}

export default Home;
