import SEOHeader from "../components/SEOHeader";
import Content from "../i18n/de";
import styled from "styled-components";
import SectionHelper from "../components/SectionHelper";
import ResponsiveImage from "../components/ResponsiveImage";

const AboutContainer = styled.section`
  .text-box {
    h1 {
      font-size: 80px;
      line-height: 1;
      margin: 0 0 10px 0;
    }
    h2 {
      font-size: 36px;
      line-height: 1;
      margin: 0 0 60px;
    }
  }
  @media (max-width: 768px) {
    .text-box {
      padding: 20px 0 0;
      h1 {
        font-size: 36px;
      }
      h2 {
        font-size: 24px;
        margin: 10px 0 20px;
      }
    }
  }
`;

function About() {
  return (
    <>
      <SEOHeader {...Content.meta.about} />
      <SectionHelper>
        <AboutContainer>
          <div className="container">
            <div className="columns">
              <div className="column is-6">
                <ResponsiveImage src="/images/team.jpg" alt="" />
                <p style={{ color: "#000" }}>Vadim & Viktor</p>
              </div>
              <div className="column is-5">
                <div className="text-box">
                  <h1>V always start</h1>
                  <h2>with a good plan, for good solutions.</h2>
                  <p>
                    Viktor ist seit Jahren erfolgreicher Unternehmer im
                    Gesundheitswesen. Auf der Suche nach neuen
                    Herausforderungen, fand er seinen Platz im Bereich Film und
                    Video wo er seine kreative Ideen endlich umsetzen kann.
                    <br />
                    <br />
                    Vadim hat in den letzten 12 Jahren als Selbstständiger DoP
                    für namhafte Künstler und Kunden wie z.B. Cassandra Steen,
                    Mercedes Benz, Monster, MCM und Citizen im Bereich Film &
                    Postproduction gearbeitet.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </AboutContainer>
      </SectionHelper>
    </>
  );
}

export default About;
