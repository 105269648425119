import axios from "axios";

export const sendContactForm = async (name, company, phone, email, message) => {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_CLOUDFUNCTION_URL, {
        name,
        company,
        phone,
        email,
        message,
        apiKey: process.env.REACT_APP_CLOUDFUNCTION_API_KEY,
      })
      .then(function (response) {
        // handle success
        console.log(response);
        return resolve(true);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        return reject();
      });
  });
};
