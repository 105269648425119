import styled from "styled-components";

const SectionHelperContainer = styled.div`
  padding: 220px 0 40px;
  @media (max-width: 768px) {
    padding: 220px 20px 40px;
  }
  @media (max-width: 767px) {
    padding: 100px 20px 40px;
  }
`;

function SectionHelper(props) {
  return (
    <SectionHelperContainer>
      <div className={props.hideContainer ? "" : "container"}>
        {props.children}
      </div>
    </SectionHelperContainer>
  );
}

export default SectionHelper;
